import React from "react";
import { NavLink } from "react-router-dom";

import ReservedAngels from "../ReservedAngels/reservedAngels";
import Form from "./../common/form";
import Select from "./../common/select";
import { appendSpreadSheetRow, userExists } from "../../utils/connectWithSheets";
import { appendSpreadSheetRows } from "./../../utils/connectWithSheets";

class FilterAngelForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      angels: this.props.angels,
      data: {
        gender: this.props.gender,
        age: this.props.age,
        minage: "",
        maxage: "",
      },
      errors: {
        gender: "",
        age: "",
        minage: "",
        maxage: "",
      },
      genders: [
        { id: "FEMALE", name: "Female" },
        { id: "MALE", name: "Male" },
        { id: "PET", name: "Pet" },
      ],
      ages: [
        { id: "0", name: "0 to 3 years", minage: "0", maxage: "3" },
        { id: "4", name: "4 to 7 years", minage: "4", maxage: "7" },
        { id: "8", name: "8 to 11 years", minage: "8", maxage: "11" },
        { id: "12", name: "12 to 15 years", minage: "12", maxage: "15" },
        { id: "15", name: "15 to 18 years", minage: "15", maxage: "18" },
        { id: "19", name: "Adult", minage: "19", maxage: "100" },
      ],
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.angels !== this.props.angels) {
      this.setState({
        angels: this.props.angels,
      });
    }
    if (prevProps.age !== this.props.age) {
      const data = { ...this.state.data };
      data["age"] = this.props.age;
      this.setState({ data });
    }
    if (prevProps.gender !== this.props.gender) {
      const data = { ...this.state.data };
      data["gender"] = this.props.gender;
      this.setState({ data });
    }
  };

  adoptReservedAngels = () => {
    const reservedAngels = this.state.angels.filter(
      (angel) => angel["Reserved"] === true
    );
    userExists(this.props.user.userName).then((response) => {
      if (!response) {
        let today = new Date();
        let date =
          today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

        const newRow = {
          Date: date,
          UserName: this.props.user.userName,
          Name: this.props.user.displayName,
          Email: this.props.user.email,
          Phone: this.props.user.telephoneNumber,
        };
        appendSpreadSheetRow(process.env.REACT_APP_GOOGLE_SHEET_USERS_ID, newRow);
      }
    });

    let adoptedAngels = [];

    reservedAngels.forEach((angel) => {
      let newRow = [
        this.props.user.userName,
        angel["Gift ID"],
        new Date().toLocaleString(),
      ];
      adoptedAngels.push(newRow);
    });
    appendSpreadSheetRows(
      process.env.REACT_APP_GOOGLE_SHEET_RESERVATIONS_ID,
      adoptedAngels
    );

    //rerender tree angels to omit reserved
    this.props.onAngelAdoption(reservedAngels);
  };

  render() {
    const { onRemoveAngelReserved, user } = this.props;
    const adoptReservedAngels = this.adoptReservedAngels;
    const reservedAngels = this.state.angels.filter(
      (angel) => angel["Reserved"] === true
    );
    return (
      <div className="container max-w-4xl mr-auto px-20 pt-20 sm:px-8 sm:pt-8 lg:px-20 lg:pt-20 antialiased font-sans">
        <div className="px-8 py-4 bg-gray-200 bg-opacity-50 rounded-lg">
          <div>
            <h2 className="text-2xl font-semibold leading-tight text-gs-navy">
              Filter Tags
            </h2>
          </div>
          <div className="my-2 flex sm:flex-row flex-col">
            <div className="flex flex-row mb-1 sm:mb-0">
              <Select
                name="gender"
                value={this.state.data["gender"]}
                label="Gender/Type"
                options={this.state.genders}
                onChange={this.props.onSelectChange}
                error={this.state.errors["gender"]}
                divStyleClass="mr-20 sm:mr-0 lg:mr-20"
              />
              <Select
                name="age"
                value={this.state.data["age"]}
                label="Age"
                options={this.state.ages}
                onChange={this.props.onSelectChange}
                error={this.state.errors["age"]}
                divStyleClass=""
              />
            </div>
          </div>
          {reservedAngels.length !== 0 && !this.props.isMobile && (
            <ReservedAngels
              angels={reservedAngels}
              onRemoveReserved={this.props.onRemoveAngelReserved}
              user={this.props.user}
              onAngelAdoption={this.props.onAngelAdoption}
              adoptReservedAngels={this.adoptReservedAngels}
            />
          )}

          {reservedAngels.length !== 0 && this.props.isMobile && (
            <NavLink
              to={{
                pathname: "/reservedTags",
                reservedAngels: { reservedAngels },
                onRemoveReserved: { onRemoveAngelReserved },
                user: { user },
                onAngelAdoption: { adoptReservedAngels },
              }}
              className="navlink"
              activeClassName="navLinkActive"
            >
              <button className="w-full">
                <span className="ml-2 mt-5px">View Your Selected Tags</span>
              </button>
            </NavLink>
          )}
          <p className="mb-4 italic">
            Please use the left and right arrows to click through the tree!
          </p>
        </div>
      </div>
    );
  }
}

export default FilterAngelForm;
